@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


html {
  scroll-behavior: smooth;
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
  width: 6px;
  background-color: #333;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


