.bg-blur-overlay {
	background-image: url('https://www.shutterstock.com/image-vector/dark-black-city-area-vector-600nw-2115534071.jpg'); /* Ensure path is correct */
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat; 
	height: 100vh; 
	position: relative; 
  }
  
  .bg-blur-overlay::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5); 
	backdrop-filter: blur(8px); 
	z-index: 1; 
  }
  
  .relative-content {
	position: relative;
	z-index: 2; 
  }
  